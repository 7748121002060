import React from 'react';
import './ReadMe.css';

export default class ReadMe extends React.Component {
  render() {
    return (
      <div>
        <div className="readme-container">
          <div className="close-button" onClick={this.props.close}></div>
          <div className="readme-copy">{this.props.copy}</div>
        </div>
      </div>
    );
  }
}
