import React from 'react';
import { IconText } from './icon-text';
import folderIcon from '../../../assets/newfolder.png';
import './icon.css';

interface Props {
  text: string;
  imageSrc?: string;
  folder?: boolean;
  extension?: string;
  open?: () => {};
}

export const Icon: React.FC<Props> = ({
  text,
  imageSrc,
  folder,
  open,
  extension,
}) => {
  return (
    <div className="icon__wrapper" onClick={open}>
      <span className="icon__extension">{extension}</span>
      {folder ? (
        <img src={folderIcon} alt="" className="icon__folder" />
      ) : (
        <img src={imageSrc} alt="" className="icon__image" />
      )}
      <IconText text={text} />
    </div>
  );
};
