import React from "react";
import { Icon as MojaveIcon } from "../v2/icon/icon";
import File from "../../assets/iconnew.png";
import chrome from "../../assets/iconchrome.png";

interface Props {
  open: () => {};
}

export const GameKeeper: React.FC<Props> = ({ open }) => {
  return (
    <div className="icon-flex">
      <a
        id="gamekeeper-1"
        href="https://github.com/LlanddewiLovesYou/gamekeeper"
        target="_blank"
        rel="noreferrer"
      >
        <MojaveIcon folder text=".git" />
      </a>
      <div id="gamekeeper-3" onClick={open}>
        <MojaveIcon extension="MD" imageSrc={File} text="README" />
      </div>
    </div>
  );
};
