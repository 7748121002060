import React from 'react';
import { Icon as MojaveIcon } from '../v2/icon/icon.tsx';
import File from '../../assets/iconnew.png';

export default class Fflickr extends React.Component {
  render() {
    return (
      <div className="icon-flex">
        <a
          id="fflickr-1"
          className="fflickr-01"
          href="https://github.com/LlanddewiLovesYou/Fflickr"
          target="_blank"
        >
          <MojaveIcon folder text=".git" />
        </a>
        {/* <a id="fflickr-2" href="https://fflickr.herokuapp.com/" target="_blank">
          <MojaveIcon extension="HTML" imageSrc={chrome} text="Fflickr.com" />
        </a> */}
        <div id="fflickr-3" onClick={this.props.open}>
          <MojaveIcon extension="MD" imageSrc={File} text="README" />
        </div>
      </div>
    );
  }
}
