import React from "react";
import { Icon as MojaveIcon } from "../v2/icon/icon";
import File from "../../assets/iconnew.png";
import chrome from "../../assets/iconchrome.png";

interface Props {
  open: () => {};
}

export const Median: React.FC<Props> = (props) => {
  return (
    <div className="icon-flex">
      <a
        id="median-1"
        href="https://github.com/LlanddewiLovesYou/median"
        target="_blank"
        rel="noreferrer"
      >
        <MojaveIcon folder text=".git" />
      </a>
      {/* <a id="median-2" href="http://iandelduca.com/median" target="_blank">
        <MojaveIcon extension="HTML" imageSrc={chrome} text="Median" />
      </a> */}
      <div id="median-3" onClick={props.open}>
        <MojaveIcon extension="MD" imageSrc={File} text="README" />
      </div>
    </div>
  );
};
