import React, { PropTypes } from 'react';
import { Icon as MojaveIcon } from '../v2/icon/icon';
import itunes from '../../assets/itunes.png';
import github from '../../assets/github.png';

export default class Fflickr extends React.Component {
  render() {
    return (
      <div className="icon-flex">
        <a
          id="fiddle-1"
          href="https://jsfiddle.net/LlanddewiLovesYou/fpvktj7o/"
          target="_blank"
        >
          <MojaveIcon text="iTunes API" imageSrc={itunes} />
        </a>
        <a
          id="fiddle-2"
          href="https://jsfiddle.net/LlanddewiLovesYou/owjfuq7d/"
          target="_blank"
        >
          <MojaveIcon text="GitHub API" imageSrc={github} />
        </a>
      </div>
    );
  }
}

Fflickr.propTypes = {};
