import React, { Component } from "react";
import "./App.css";
import VirtualDesktop from "./components/VirtualDesktop/VirtualDesktop";

class App extends Component {
  render() {
    return (
      <div className="App">
        <VirtualDesktop />
      </div>
    );
  }
}

export default App;
