import React from "react";
import pdf from "../../assets/IDDMasterResume10_19_23.pdf";

import "./Resume.css";

export default class Resume extends React.Component {
  render() {
    return (
      <div>
        <label for="resume-image">
          <a
            href={pdf}
            target="_blank"
            rel="noreferrer"
            className="resume-container"
          >
            {this.props.src.map((page) => {
              return (
                <img
                  id="resume-image"
                  className="resume-img"
                  src={page}
                  alt=""
                />
              );
            })}
          </a>
        </label>
      </div>
    );
  }
}
