import React from 'react';
import { Icon as MojaveIcon } from '../v2/icon/icon.tsx';
import File from '../../assets/iconnew.png';

export default class YayQuery extends React.Component {
  render() {
    return (
      <div className="icon-flex">
        <a
          id="yayquery-1"
          href="https://github.com/LlanddewiLovesYou/YayQuery"
          target="_blank"
        >
          <MojaveIcon folder text=".git" />
        </a>
        <div id="yayquery-2" onClick={this.props.open}>
          <MojaveIcon extension="MD" imageSrc={File} text="README" />
        </div>
      </div>
    );
  }
}
