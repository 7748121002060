import React from 'react'
import './icon-text.css'

interface Props {
    text: string
}

export const IconText: React.FC<Props> = (props) => {
    const { text } = props
    return (
        <div className='icon__text'>
            {text}
        </div>
    )
}