import React from "react";
import { Icon as MojaveIcon } from "../v2/icon/icon.tsx";
import File from "../../assets/iconnew.png";
import chrome from "../../assets/iconchrome.png";

export default class Penultimate extends React.Component {
  render() {
    return (
      <div className="icon-flex">
        <a
          id="mackcali-1"
          href="https://github.com/LlanddewiLovesYou/mack-cali"
          target="_blank"
          rel="noreferrer"
        >
          <MojaveIcon folder text=".git" />
        </a>
        {/* <a
          id="mackcali-2"
          href="http://iandelduca.com/mack-cali"
          target="_blank"
        >
          <MojaveIcon extension="HTML" imageSrc={chrome} text="Mack Cali" />
        </a> */}
        <div id="mackcali-3" onClick={this.props.open}>
          <MojaveIcon extension="MD" imageSrc={File} text="README" />
        </div>
      </div>
    );
  }
}
